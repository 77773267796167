<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Ganti Nomor Batch</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Gudang / Ganti Nomor Batch</span>
      </div>
    </div>
    <div class="grid">
      <div class="col-1 md:col-3"></div>
      <div class="col-10 md:col-6">
        <div class="card bg-white pb-0">
          <form @submit.prevent="save(!v$.$invalid)">
            <div class="card bg-white">
              <div class="formgrid grid">
                <div class="field col-12">
                  <label
                    :class="{
                      'p-error': v$.obat.$invalid && submitted,
                    }"
                  >
                    Nama Barang
                  </label>
                  <AutoComplete
                    v-model="v$.obat.$model"
                    :min-length="2"
                    :suggestions="acObat"
                    field="nama"
                    class="w-full"
                    input-class="w-full"
                    placeholder="Barang"
                    autofocus
                    @complete="searchObat($event)"
                    @item-select="selectObat"
                    :class="{
                      'p-invalid': v$.obat.$invalid && submitted,
                    }"
                  >
                    <template #item="slotProps">
                      <div>
                        {{ slotProps.item.kode }} -
                        {{ slotProps.item.nama }}
                      </div>
                    </template>
                  </AutoComplete>
                  <small
                    v-if="
                      (v$.obat.$invalid && submitted) ||
                      v$.obat.$pending.$response
                    "
                    class="p-error"
                    >{{ v$.obat.required.$message }}</small
                  >
                </div>
                <div class="field col-8 md:col-8">
                  <label
                    :class="{
                      'p-error': v$.obat.$invalid && submitted,
                    }"
                  >
                    No. Batch Lama
                  </label>
                  <Dropdown
                    v-model="v$.old_batch.$model"
                    placeholder="No. Batch Lama"
                    optionLabel="batch_no"
                    :options="list_batch"
                    :class="{
                      'p-invalid': v$.old_batch.$invalid && submitted,
                    }"
                    class="w-full"
                    :filter="true"
                    filterPlaceholder="Cari No. Batch"
                    @change="selectOldBatch"
                  >
                    <template #value="slotProps">
                      <div v-if="slotProps.value">
                        {{ slotProps.value.batch_no }}
                      </div>
                      <div v-else>No. Batch Lama</div>
                    </template>
                    <template #option="slotProps">
                      <div>
                        <div>
                          <strong>{{ slotProps.option.batch_no }}</strong>
                        </div>
                        <small>
                          <div>Qty: {{ slotProps.option.quantity }}</div>
                          <div>
                            Exp: {{ formatDate(slotProps.option.expired_at) }}
                          </div>
                          <div>
                            Supplier: {{ slotProps.option.nama_supplier }}
                          </div>
                        </small>
                      </div>
                    </template>
                  </Dropdown>
                  <small
                    v-if="
                      (v$.old_batch.$invalid && submitted) ||
                      v$.old_batch.$pending.$response
                    "
                    class="p-error"
                    >{{ v$.old_batch.required.$message }}</small
                  >
                </div>
                <div class="field col-4 md:col-4">
                  <label>Qty</label>
                  <InputText
                    v-model="old_quantity"
                    type="number"
                    :min="1"
                    class="w-full"
                    input-class="w-full"
                    readonly
                  />
                </div>
                <div class="field col-5">
                  <label
                    :class="{
                      'p-error': v$.form.batch_no.$invalid && submitted,
                    }"
                  >
                    No. Batch Baru
                  </label>
                  <Dropdown
                    v-model="v$.form.batch_no.$model"
                    placeholder="No. Batch Baru"
                    optionLabel="batch_no"
                    :options="list_batch_new"
                    :class="{
                      'p-invalid': v$.form.batch_no.$invalid && submitted,
                    }"
                    class="w-full"
                    :filter="true"
                    :editable="true"
                    filterPlaceholder="Cari No. Batch"
                    @change="selectNewBatch"
                  >
                    <template #value="slotProps">
                      <div v-if="slotProps.value">
                        {{ slotProps.value.batch_no }}
                      </div>
                      <div v-else>No. Batch Lama</div>
                    </template>
                    <template #option="slotProps">
                      <div>
                        <div>
                          <strong>{{ slotProps.option.batch_no }}</strong>
                        </div>
                        <small>
                          <div>Qty: {{ slotProps.option.quantity }}</div>
                          <div>
                            Exp: {{ formatDate(slotProps.option.expired_at) }}
                          </div>
                          <div>
                            Supplier: {{ slotProps.option.nama_supplier }}
                          </div>
                        </small>
                      </div>
                    </template>
                  </Dropdown>
                  <small
                    v-if="
                      (v$.form.batch_no.$invalid && submitted) ||
                      v$.form.batch_no.$pending.$response
                    "
                    class="p-error"
                    >{{ v$.form.batch_no.required.$message }}</small
                  >
                </div>
                <div class="field col-3">
                  <label>Qty</label>
                  <InputText
                    v-model="form.quantity"
                    type="number"
                    :min="1"
                    class="w-full"
                    input-class="w-full"
                  />
                </div>
                <div class="field col-4">
                  <label>Tanggal Expired</label>
                  <Calendar
                    v-model="form.expired_at"
                    class="w-full"
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div class="field col-12">
                  <div class="flex justify-content-end">
                    <Button
                      icon="pi pi-save"
                      class="mr-2"
                      label="Simpan"
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-1 md:col-3"></div>
    </div>
  </div>
</template>

<script>
import ObatService from '@/services/ObatService'
import errorHandler from '@/helpers/error-handler'
import { helpers, required } from '@vuelidate/validators'
import { formatDate } from '@/helpers'
import useVuelidate from '@vuelidate/core'
import dayjs from 'dayjs'

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {},
  data() {
    return {
      submitted: false,
      old_batch: null,
      old_quantity: 0,
      form: {
        // supplier_id: 0,
        batch_id: 0,
        batch_no: '',
        quantity: 0,
        expired_at: new Date(),
      },
      acObat: [],
      list_batch: [],
      list_batch_new: [],
      obatService: null,
      isLoading: false,
      obat: null,
    }
  },
  watch: {
    /*
    list_batch_new() {
      console.log('list_batch', this.list_batch)
      return this.list_batch.filter((e) => {
        e.id !== this.old_batch.id
      })
    },
    */
  },
  created() {
    this.obatService = new ObatService()
  },
  mounted() {},
  methods: {
    searchObat(e) {
      this.obatService
        .get(`?filter[nama]=${e.query}&filter[status]=aktif`)
        .then((res) => {
          this.acObat = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Obat', this)
        })
    },
    selectObat(e) {
      this.obat = e.value
      this.onGetBatch()
    },
    onGetBatch() {
      const id = this.obat.id
      this.obatService.getBatch(id).then((res) => {
        this.list_batch = res.data.data
        if (this.list_batch) {
          this.old_batch = this.list_batch[0]
          this.old_quantity = this.old_batch.quantity
          this.form.quantity = this.old_batch.quantity
          this.form.quantity = this.old_batch.quantity
          this.form.expired_at = new Date (this.old_batch.expired_at)
          // this.form.supplier_id = this.old_batch.supplier_id
          this.list_batch_new = this.list_batch.filter(
            (e) => e.id !== this.old_batch.id
          )
        }
      })
    },
    selectOldBatch(e) {
      this.old_quantity = e.value.quantity
      this.form.quantity = e.value.quantity
      this.form.expired_at = new Date(e.value.expired_at)
      this.list_batch_new = this.list_batch.filter(
        (e) => e.id !== this.old_batch.id
      )
    },
    selectNewBatch(e) {
      this.form.batch_no = e.value.batch_no
      this.form.quantity = e.value.quantity
      this.form.expired_at = new Date(e.value.expired_at)
    },
    save(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.isLoading = true
      const form = Object.assign({}, this.form)
      form.batch_id = this.old_batch.id
      form.expired_at = this.form.expired_at
        ? dayjs(this.form.expired_at).format('YYYY-MM-DD HH:mm:ss')
        : null
      const id = this.obat.id
      this.obatService
        .updateBatch(id, form)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Ganti Batch',
              detail: 'Data berhasil disimpan.',
              life: 3000,
            })
            this.reset()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Ganti Batch', this)
        })
        .finally(() => (this.isLoading = false))
    },
    reset() {
      this.submitted = false
      this.obat = null
      this.old_batch = null
      this.old_quantity = 0
      this.form = {
        // supplier_id: 0,
        batch_id: 0,
        batch_no: '',
        quantity: 0,
        expired_at: null,
      }
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
  validations() {
    return {
      obat: {
        required: helpers.withMessage('Nama Barang harus diisi.', required),
      },
      old_batch: {
        required: helpers.withMessage('No. Batch Lama harus diisi.', required),
      },
      form: {
        batch_no: {
          required: helpers.withMessage(
            'No. Batch Baru harus diisi.',
            required
          ),
        },
      },
    }
  },
}
</script>
